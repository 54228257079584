<template>
  <div>
    <app-loader />
    <v-card-text class="mt-5 mb-3 px-12">
      <v-card flat class="mb-8" outlined v-if="isEditMode">
        <v-card-text>
          <v-img :src="url" aspect-ratio="1.5" :height="height" :key="'asd'" class="profile-picture">
            <template v-slot:default>
              <v-row justify="center" v-if="_.isNil(url)">
                <img :src="anonymousImage" alt="anonymous" />
              </v-row>
            </template>
          </v-img>
        </v-card-text>
      </v-card>
      <app-form :fields="fields" :submit-button="submitButton" @file-attached="handleAttach"
        disable-cancel-button footerClass="col-12 text-center mt-5" @submitted="handleSubmit" />
    </v-card-text>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {employeeEstOptions, industries} from 'constants';
import moment from 'moment-timezone';

export default {
  name: 'Register',
  props: {
    isEditMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      moment,
      employeeEstOptions: employeeEstOptions,
      industries: industries,
      anonymousImage: '/images/anonymous.png',
      url: '',
      fields: {
        logo: {
          type: 'file',
          showImage: true,
          key: 'logo',
          value: null,
          pastFile: '',
          clearable: true,
          showSize: true,
          accept: 'image/*',
          icon: 'mdi-camera',
          label: this.$t('models.company.attributes.logo'),
          rules: [{name: this.isEditMode ? '' : 'required'}],
          classes: 'col-12 py-0',
          serverErrors: []
        },
        name: {
          type: 'text',
          key: this.companyName(),
          value: '',
          disabled: this.isEditMode,
          label: this.$t('models.company.attributes.companyName'),
          rules: [{name: 'required'}],
          classes: 'col-12 py-0',
          serverErrors: []
        },
        subdomain: {
          type: 'text',
          key: 'subdomain',
          value: '',
          hidden: this.isEditMode,
          label: this.$t('models.company.attributes.subdomain'),
          rules: [{
            name: this.isEditMode ? '' : 'required'
          }],
          classes: 'col-12 py-0',
          serverErrors: []
        },
        industry: {
          type: 'select',
          key: 'industry',
          value: '',
          label: this.$t('models.company.attributes.industry'),
          classes: 'col-md-6 col-sm-12 py-0',
          options: [],
          itemText: 'value',
          itemValue: 'key',
          serverErrors: []
        },
        employeeCountRange: {
          type: 'select',
          key: 'employeeCountRange',
          value: '',
          label: this.$t('models.company.attributes.employeeCountRange'),
          classes: 'col-md-6 col-sm-12 py-0',
          options: [],
          itemText: 'value',
          itemValue: 'key',
          serverErrors: []
        },
        officeName: {
          type: 'text',
          key: 'officeName',
          value: '',
          label: `${this.$tc('models.office.entity', 1)} ${this.$t('models.office.attributes.name')}`,
          disabled: this.isEditMode,
          hidden: this.isEditMode,
          rules: [{
            name: this.isEditMode ? '' : 'required'
          }],
          classes: 'col-md-6 col-12 py-0 mt-5',
          serverErrors: []
        },
        timezone: {
          type: 'select',
          key: 'timezone',
          value: '',
          placeholder: `${this.$tc('models.office.entity', 1)} ${this.$t('models.office.attributes.timezone')}`,
          label: this.$t('models.office.attributes.timezone'),
          disabled: this.isEditMode,
          hidden: this.isEditMode,
          rules: [{
            name: this.isEditMode ? '' : 'required'
          }],
          options: Object.values(moment.tz.names()),
          itemText: 'text',
          itemValue: 'key',
          classes: 'col-md-6 col-12 py-0 mt-md-5',
          serverErrors: []
        },
        weekends: {
          type: 'select',
          multiple: true,
          key: 'weekends',
          value: '',
          placeholder: `${this.$tc('models.office.entity', 1)} ${this.$t('models.office.attributes.weekends')}`,
          label: this.$t('models.office.attributes.weekends'),
          disabled: this.isEditMode,
          hidden: this.isEditMode,
          rules: [{
            name: this.isEditMode ? '' : 'required'
          }],
          options: Object.values(moment.weekdays()),
          itemText: 'text',
          itemValue: 'key',
          classes: 'col-md-6 col-12 py-0',
          serverErrors: []
        },
        fullTimeWorkingDay: {
          type: 'number',
          key: 'fullTimeWorkingDay',
          value: '',
          label: `${this.$tc('models.office.entity', 1)} ${this.$t('models.office.attributes.fullTimeWorkingDay')}`,
          disabled: this.isEditMode,
          hidden: this.isEditMode,
          rules: [{
            name: this.isEditMode ? '' : 'required'
          }],
          classes: 'col-md-6 col-12 py-0',
          serverErrors: []
        },
        email: {
          type: 'email',
          key: 'email',
          value: '',
          disabled: this.isEditMode,
          hidden: this.isEditMode,
          label: this.$t('models.company.attributes.email'),
          rules: [{
            name: this.isEditMode ? '' : 'required'
          },
          {
            name: this.isEditMode ? '' : 'email'
          }],
          classes: 'col-12 py-0 mt-5',
          serverErrors: []
        },
        firstName: {
          type: 'text',
          key: 'firstName',
          value: '',
          disabled: this.isEditMode,
          hidden: this.isEditMode,
          label: this.$t('models.company.attributes.firstName'),
          rules: [{
            name: this.isEditMode ? '' : 'required'
          }],
          classes: 'col-md-6 col-sm-12 py-0',
          serverErrors: []
        },
        lastName: {
          type: 'text',
          key: 'lastName',
          value: '',
          disabled: this.isEditMode,
          hidden: this.isEditMode,
          label: this.$t('models.company.attributes.lastName'),
          rules: [{
            name: this.isEditMode ? '' : 'required'
          }],
          classes: 'col-md-6 col-sm-12 py-0',
          serverErrors: []
        }
      },
      submitButton: {
        label: this.isEditMode ? this.$t('actions.save') : this.$t('actions.register')
      }
    };
  },
  computed: {
    ...mapGetters(['company']),
    height() {
      return this._.isNil(this.url) ? 200 : 'auto';
    }
  },
  methods: {
    ...mapActions(['createNewCompany', 'getCompany', 'updateCompany']),
    companyName() {
      if (this.isEditMode) {
        return 'name';
      } else {
        return 'companyName';
      }
    },
    handleSubmit(data) {
      const companyData = new FormData();
      for (const key in data) {
        if (data[key]) {
          if (key === 'logo') {
            if (data[key]) {
              companyData.append(key, data[key][0]);
            }
          } else {
            companyData.append(key, data[key]);
          }
        }
      }
      if (this.isEditMode) {
        const form = {
          companyId: this.company.id,
          form: companyData
        };
        this.updateCompany(form).then((response) => {
          if (!this.successCode(response.status)) {
            this.displayInlineServerErrors(this.fields, response.errors);
          }
        });
      } else {
        this.createNewCompany(companyData);
      }
    },
    handleAttach(images) {
      if (this.isEditMode) {
        this.url = images.length > 0 ? URL.createObjectURL(images[0]) : undefined;
      }
    }
  },
  created() {
    this.fields.industry.options = this._.map(this.industries, (industry) => {
      return {key: industry, value: this.$t(`models.company.attributes.industries.${industry}`)};
    });
    this.fields.employeeCountRange.options = this.employeeEstOptions;
    if (this.isEditMode) {
      this.getCompany().then(() => {
        this.fields.name.value = this.company.name;
        this.fields.industry.value = this.company.industry;
        this.fields.employeeCountRange.value = this.company.employeeCountRange;
        this.url = this.getFileUrl(this.company.logo);
        this.fields.logo.pastFile = this.company.logoName;
      });
    } else {
      this.url = `/images/${process.env.VUE_APP_LOGO}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-picture {
  max-height: 600px;
  max-width: 600px;
  margin: auto;
}
</style>
