<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="breadcrumbs" />
    <v-card outlined flat class="fill-height" width="100%">
      <company-form :isEditMode="true" />
    </v-card>

  </v-container>
</template>

<script>
import companyForm from 'components/companies/Form.vue';

export default {
  name: 'CompanySettings',
  data() {
    return {
      title: {
        text: this.$t('layout.mainMenu.companyProfile'),
        icon: 'mdi-briefcase-edit'
      },
      breadcrumbs: [{
        text: this.$t('layout.mainMenu.home'),
        to: {name: 'Home'}
      },
      {
        text: this.$t('layout.mainMenu.settings'),
        to: {name: 'CompanySettings'},
        exact: true
      },
      {
        text: this.$t('layout.mainMenu.companyProfile'),
        to: {name: 'CompanySettings'},
        exact: true
      }]
    };
  },
  components: {
    companyForm
  }
};
</script>
